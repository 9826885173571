// 将数字强制保留两位小数
export function mustTwoDecimal (number) {
  var num = number.toString()
  var index = num.indexOf('.')
  if (index <= 0) {
    num += '.'
    index = num.length - 1
  }
  while ((index + 3) !== num.length) {
    num += '0'
  }
  return num
}

// 时间戳转化
export function timeConversion (time) {
  var now = new Date(time * 1000)
  var year = now.getFullYear()
  var month = padZero(now.getMonth() + 1)
  var date = padZero(now.getDate())
  var hour = padZero(now.getHours())
  var minute = padZero(now.getMinutes())
  var second = padZero(now.getSeconds())
  return year + '-' + month + '-' + date + '   ' + hour + ':' + minute + ':' + second
}
function padZero(n) {
  return n > 9 ? n : '0' + n
}
// 手机号码中间隐藏函数
export function hideCenterPhone (phone) {
  const phoneStart = phone.substring(0, 3)
  const phoneEnd = phone.substring(7, 11)
  return phoneStart + '****' + phoneEnd
}

// 名字开头隐藏函数
export function hideStartName (name) {
  const nameEnd = name.substring(1)
  return '*' + nameEnd
}
